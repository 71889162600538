<template>

  <form @submit.prevent="next">

    <AppHeader theme="white">
      お問い合わせ
    </AppHeader>

    <section class="contents">

      <div class="contentInner">

        <div class="white_bg_area2">

          <div :class="{ 'form-group--error': $v.input_name.$error }">
            <label for="name">お名前</label>
            <div class="error" v-if="$v.input_name.$dirty && !$v.input_name.required">入力してください</div>
            <input type="text" id="name"
                   :value="input_name" @input="$emit('update:input_name', $event.target.value)"
                   placeholder="お名前を入力してください">
          </div>

          <div :class="{ 'form-group--error': $v.input_email.$error }">
            <label for="mail">メールアドレス</label>
            <div class="error" v-if="$v.input_email.$dirty && !$v.input_email.required">入力してください</div>
            <input type="email" id="mail"
                   :value="input_email" @input="$emit('update:input_email', $event.target.value)"
                   placeholder="メールアドレスを入力してください">
          </div>

          <div :class="{ 'form-group--error': $v.input_type.$error }">
            <label for="content_type">お問い合わせの種類</label>
            <div class="error" v-if="$v.input_type.$dirty && !$v.input_type.required">入力してください</div>
            <div class="select-wrap">
              <select id="content_type"
                      :value="input_type" @input="$emit('update:input_type', $event.target.value)">
                <option value>お問い合わせの種類を選択してください</option>
                <option v-for="option in typeOptions" :key="option">{{ option }}</option>
              </select>
            </div><!-- .select-wrap -->
          </div>

          <div :class="{ 'form-group--error': $v.input_content.$error }">
            <label for="content">お問い合わせの内容</label>
            <div class="error" v-if="$v.input_content.$dirty && !$v.input_content.required">入力してください</div>
            <textarea id="content"
                      :value="input_content" @input="$emit('update:input_content', $event.target.value)"
                      rows="5" cols="" placeholder="お問い合わせ内容を入力してください"></textarea>
          </div>

          <div class="bt_form">
            <button type="submit" value="send">確認</button>
          </div>

        </div><!-- .white_bg_area -->

      </div><!--.contentInner-->

    </section><!--.contents-->

    <AppFooter theme="yellow" fixed></AppFooter>

  </form>

</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
export default {
  name: 'ContactForm',
  components: {AppHeader, AppFooter},
  props: {
    input_name: String,
    input_email: String,
    input_type: String,
    input_content: String,
  },
  data () {
    return {
      typeOptions: [
        '会員登録やログインについて',
        '購読について',
        '機能の説明や要望について',
        'その他',
      ]
    }
  },
  mixins: [validationMixin],
  validations: {
    input_name: {
      required,
    },
    input_email: {
      required,
    },
    input_type: {
      required,
    },
    input_content: {
      required,
    },
  },
  methods: {
    next () {
      if (!this.invalid()) {
        this.$router.push({name: 'contact-confirm'});
      }
    },
    invalid () {
      this.$v.$touch();
      return this.$v.$invalid;
    }
  }
}
</script>

<style lang="scss" scoped>
#wrap {
  .form-group--error {
    input, select, textarea {
      border: 1px solid #ffd3ce;
      background-color: #ffd3ce;
    }
  }
  .error {
    font-size: 10rem;
    color: #ff7666;
    margin: 5px 0 -5px;
  }
}
</style>